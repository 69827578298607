import React, { useState, useEffect, useRef, forwardRef } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { amountFormatter } from "../../../../helpers/constants/functions"
import topcup from "../../../../images/topcup.jpg"
import qrCode from "../../../../images/qr.jpeg"
import noCOntent from "../../../../images/no-content.png"
import print from "../../../../images/print.png"
import { useReactToPrint } from 'react-to-print';
import { CloseRemoveIcon } from "../../../../helpers/icons/icon-close-remove"
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  GET_EVENT_RATING,
  GET_EVENTS,
  GET_PLAYER_GAMES,
  GET_PLAYER_CHART_STATISTIC,
  GET_AFISHA_INFO
} from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { EyeIcon } from "../../../../helpers/icons/icon-eye"
import { YouTubeIcon } from "../../../../helpers/icons/icon-youtube"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment"
// import { usePDF } from 'react-to-pdf';
// import moment from "moment"
// import CookieHelper from "../../../../helpers/cookie-helper";
import classNames from "classnames"
import CustomCarousel from "../../../../helpers/custom-carousel";
import OnePlayer from "../../../../helpers/one-player"
import Spinner from "../../../../helpers/spinner"
import { enqueueSnackbar } from "notistack";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { ArrowSimpleIcon } from "../../../../svg-icons/icon-simple-arrow"
import DoneIcon from '@mui/icons-material/Done';

import "./styles.scss"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`} s
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </div>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RatingTable({ eventId = null }) {

  // const currentDate = moment().format("DD-MM-YYYY HH:mm");
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [events, setEvents] = useState([]);
  const [rating, setRating] = useState([]);
  const [ratingForCarousel, setRatingForCarousel] = useState([]);
  // const [forFileDownload, setRatingForFileDownload] = useState([]);
  const [searchPlayer, setSearchPlayer] = useState("");
  const [fetchEventGames, setFetchEventGames] = useState(true)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [fetchPlayerHistory, setFetchPlayerHistory] = useState(false)
  const [showOnlyActivPlayers, setShowOnlyActivPlayers] = useState(eventId > 0)
  const [playerInfo, setPlayerInfo] = useState(null)
  const [redirectId, setRedirectId] = useState(null)
  const [playerGames, setPlayerGames] = useState([])
  const [playerChartStatistic, setPlayerChartStatistic] = useState([])
  const [eventStatistic, setEventStatistic] = useState([])
  const [afishaInfo, setAfishaInfo] = useState([])
  const [isFetchingAfisha, setIsFetchingAfisha] = useState(false)
  const [error, setError] = useState("")
  // const [tcaToken, setTcaToken] = useState(null);
  const [value, setValue] = useState(0);
  // const { toPDF, targetRef } = usePDF({ filename: `tca-rating-delta-${currentDate}.pdf` });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {

    setIsFetchingAfisha(true)
    const formData = new FormData()

    axiosFetch(GET_AFISHA_INFO, formData).then(res => {
      setAfishaInfo(res)
      setIsFetchingAfisha(false)
    })

  }, [])

  useEffect(() => {
    document.title = `TCA | Rating`
    // const isToken = CookieHelper("get", "tca-token");
    // setTcaToken(isToken)
    const selectedEventId = eventId || selectedEvent?.id
    if (selectedEventId) {
      setError("")
      setFetchEventGames(true)
      const formData = new FormData()
      formData.append("eventId", selectedEventId)

      axiosFetch(GET_EVENT_RATING, formData).then(res => {

        if (res[0].noGames === true) {
          setError("Для данного события рейтинг ещё не пересчитывался");
          setRatingForCarousel(res)
        } else {
          const sortedByDeltaDesc = [...res]
            .sort((a, b) => b.delta - a.delta)
            .slice(0, 5);
          setEventStatistic(sortedByDeltaDesc)
          setRating(res)
          setRatingForCarousel(res)
        }
        setTimeout(() => {
          setFetchEventGames(false)
        }, 1000);

      })
    }

  }, [selectedEvent, eventId])

  useEffect(() => {
    if (playerInfo?.id) {
      setError("")
      setFetchPlayerHistory(true)
      const formData = new FormData()
      formData.append("playerId", playerInfo.id)
      formData.append("eventId", selectedEvent.id)

      axiosFetch(GET_PLAYER_GAMES, formData).then(res => {
        //setPlayerName(res[0]?.playerOne || "")
        setPlayerGames(res)
        setTimeout(() => {
          setFetchPlayerHistory(false)
        }, 1000);
      })

      axiosFetch(GET_PLAYER_CHART_STATISTIC, formData).then(res => {
        setPlayerChartStatistic(res)
        setTimeout(() => {
          setFetchPlayerHistory(false)
        }, 1000);
      })
    }

  }, [playerInfo?.id, selectedEvent])

  useEffect(() => {
    const formData = new FormData()
    formData.append("fullInfo", 0)
    axiosFetch(GET_EVENTS, formData).then(res => {
      setEvents(res)
      const selEvent = eventId ? res.filter(event => event.id === eventId) : res
      setSelectedEvent(selEvent[0])
    })
  }, [eventId])

  const handleChange = (event, v) => {
    setValue(v);
  };



  const renderPlayerActivity = (number, activity) => {
    // let actArr = []

    // for (let i = 0; i < number; i++) {
    //   actArr.push(i)
    // }

    // const content = actArr.map(() => {`
    const cName = activity === "wins" ? "green" : "red"
    return (
      <div className={"count-games"}>
        <div
          className={classNames({
            'square-activity': true,
            [`${cName}`]: true
            //`${cName}`: true
          })} />
        <span>
          x{number}
        </span>
      </div>
    )
    // })

    // return content
  }

  const renderDeltaPlaces = (prevPlace, place) => {

    const deltaPlace = prevPlace < place ? place - prevPlace :
      prevPlace > place ? prevPlace - place : "";
    const plusMinus = prevPlace < place ? `-` : prevPlace > place ? `+` : "";

    return (
      <div className="places-arrows delta-icon">
        {deltaPlace && place !== deltaPlace &&
          <>
            <span
              className={classNames({
                'icon-color-up': prevPlace < place,
                'icon-color-down': prevPlace > place
              })}
            >
              {plusMinus}{deltaPlace}
            </span>
            <ArrowSimpleIcon
              className={classNames({
                'simple-icon': true,
                'hide-arrow': prevPlace === place,
                'icon-color-up': prevPlace < place,
                'icon-color-down': prevPlace > place
              })}
            />
          </>
        }

      </div>
    )
  }

  const showHistory = (e, row) => {
    e.stopPropagation();
    setPlayerInfo(row)
    setShowHistoryModal(true)
  }

  const closePlayerHistory = () => {
    setShowHistoryModal(false)
    setPlayerInfo(null)
    setPlayerGames([])
    setValue(0);
  }

  const renderPlayerGames = () => {

    const content = (playerGames || []).map((game, index) => {

      const { playerTwo, delta, scoreOne, scoreTwo, currentRatingPlayerTwo } = game
      return (
        <Grid container spacing={1} className="result-line" key={index}>
          {/* <Grid item xs={3} className="t-center t-row">
            <b>{playerOne}</b>
          </Grid> */}
          <Grid item xs={6} className="d-flex">
            <b>({amountFormatter(currentRatingPlayerTwo)}) {playerTwo}</b>

          </Grid>
          <Grid item xs={3} className="t-center match-score">
            <b>{scoreOne} : {scoreTwo}</b>
          </Grid>
          <Grid item xs={3}>
            <span className={`${Number(scoreOne) < Number(scoreTwo) ? "delta-minus" : "delta-plus"}`}>{delta > 0 && "+"}{delta}</span>
          </Grid>

        </Grid>
      )
    })

    return content
  }

  const renderTotalDelta = () => {
    let summ = 0
    playerGames.forEach(g => summ += Number(g.delta))
    return (
      <div className="total-delta">
        Итого: <span className={`${Number(summ) < 0 ? "delta-minus" : summ !== 0 ? "delta-plus" : ""}`}>{summ > 0 && "+"}{amountFormatter(summ)}</span>
      </div>
    )
  }

  const renderTabsContent = () => {
    return (
      <>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered className="statistic-tabs">
          <Tab label={`${selectedEvent.name} (${selectedEvent.date})`} />
          <Tab label="Вся статистика" />
        </Tabs>
        <>
          <CustomTabPanel value={value} index={0}>
            {playerGames !== "NO_GAMES" ? renderPlayerGames() : "Нет игр"}
            {playerGames !== "NO_GAMES" && renderTotalDelta()}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {statisticChart()}
          </CustomTabPanel>

        </>
      </>

    )
  }

  const statisticChart = () => {
    return (
      <LineChart
        width={600}
        height={300}
        data={playerChartStatistic}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="wins"
          stroke=" #066e29"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="losses"
          stroke="#ff0000"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="delta"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    )
  }

  const renderHistoryModal = () => {
    return (
      <Dialog
        open={showHistoryModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closePlayerHistory()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="player-games" style={{ minWidth: 600 }}>
          <DialogTitle className="modal-player-name">
            <span style={{ display: "block", textAlign: "center" }}>
              История игрока: <b
                style={{ cursor: "pointer", }}
                onClick={() => setRedirectId(playerInfo?.id)}
              >
                {playerInfo?.name}{` #${playerInfo?.place}`}
              </b>
            </span>

          </DialogTitle>
          <DialogContent style={{ textAlign: `${fetchPlayerHistory && "center"}` }}>
            <span style={{ marginBottom: 20, fontWeight: 700, display: "block" }}>
              ({`${amountFormatter(playerInfo?.pastRating)} -> ${amountFormatter(playerInfo?.rating)}`})
            </span>
            {fetchPlayerHistory ?
              // <CircularProgress className="circular-progress" size={18} /> 
              <Spinner />
              :
              selectedEvent && renderTabsContent()}
          </DialogContent>
          <DialogActions>

            <Button onClick={() => closePlayerHistory()} variant="contained">Отмена</Button>

          </DialogActions>
        </div>

      </Dialog >
    )
  }

  const searchText = (items = [], text) => {
    return items.filter(item => {
      return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
    })
  }

  const searhOnlyActivePlaers = (items = [], text) => {



    const filteredArr = items.filter(i => i.isPlayed > 0)


    const res = filteredArr.filter(item => {
      return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
    })

    return res
  }

  const onCellClick = (row) => {

    setRedirectId(Number(row.id))
  }

  const renderTablePlace = (row) => {
    return (
      <div className="place-activity">
        <div className="player-event-place">#{row.place}</div>
        {/* <div className="place-games">
          <div className="place-games-activity">{row.wins > 0 && renderPlayerActivity(row.wins, "wins")}</div>
          <div className="place-games-activity">{row.losses > 0 && renderPlayerActivity(row.losses, "losses")}</div>
        </div> */}
      </div>
    )
  }

  const renderPlayerName = (row) => {
    return (
      <div className={"player-name"} >
        {row.verificated === 1 && <DoneIcon className="player-verificated" />}
        <div style={{ backgroundImage: `url(${row.image})` }} className="player-image" />

        <div className="player-name-games">
          <div>
            {row.name}
          </div>
          <div className="place-games">
            <div className="place-games-activity">{row.wins > 0 && renderPlayerActivity(row.wins, "wins")}</div>
            <div className="place-games-activity">{row.losses > 0 && renderPlayerActivity(row.losses, "losses")}</div>
          </div>
        </div>
      </div>
    )
  }

  const renderEventDelta = (row) => {
    return (
      <div
        className={`${Number(row.delta) < 0 ? "delta-minus" : row.isPlayed > 0 ? "delta-plus" : "delta-zero"}`}
      >
        <span>
          {(Number(row.delta) > 0 || (row.isPlayed > 0 && Number(row.delta) === 0)) && "+"}{Number(row.delta) === 0 ? 0 : amountFormatter(row.delta)}
        </span>
      </div>
    )
  }

  const renderLast4EventRatingContent = (games = []) => {
    const content = games.map((game, index) => {

      const { isPlayed, eventRating, eventPastRating } = game
      const delta = eventRating - eventPastRating
      const rating = isPlayed > 0 ? amountFormatter(eventRating) : " - "
      const rPastDelta = isPlayed > 0 ? renderEventDelta({ delta, isPlayed }) : ""

      return (
        <div className="table-event last-event-match" key={index}>
          <div className="current-rating">
            {rating}
            <div className="past-delta">
              {rPastDelta}
            </div>

          </div>



        </div>
      )
    })

    return content
  }

  const renderLast4EventHeader = (games = []) => {
    const content = games.map((game, index) => {
      const { date } = game
      return (
        <div className="table-event" key={index}>{date}</div>
      )
    })

    return content
  }

  const renderPlayerCard = (missed) => {

    // let content 

    const cards = [];

    const eventsMissToGo = 10 - missed

    for (let index = eventsMissToGo; index <= 3; index++) {
      cards.push(index)
    }


    const content = cards.map(card => {
      return (
        <div className={`card-order card-color-${card}`}>
        </div>
      )
    })

    return content

    // switch (missed) {
    //   case 7:

    //     break;

    //   default:
    //     break;
    // }
  }

  const renderNewTable = (table) => {

    const content = table.map((line, index) => {

      const { prevPlace, place, rating, last4Matches, eventsMissed, coach, isPlayed,
        // gold, silver, bronz 
      } = line
      return (
        <div className="table-line rating-line" key={index} onClick={() => onCellClick(line)}>
          <div className="table-left-container">
            <div className="table-place">
              {renderTablePlace(line)}

              {/* <div className="place-league-container">
                {gold > 0 && <div className="place-league">
                  <EmojiEventsIcon sx={{ fill: "#FFD700" }}/>
                  <span>x{gold}</span>
                </div>}
                {silver > 0 && <div className="place-league">
                  <EmojiEventsIcon sx={{ fill: "#A9A9A9" }}/>
                  <span>x{silver}</span>
                </div>}
                {bronz > 0 && <div className="place-league">
                  <EmojiEventsIcon sx={{ fill: "#b08d57" }}/>
                  <span>x{bronz}</span>
                </div>}
              </div> */}
            </div>
            <div className="table-player-name">
              {renderPlayerName(line)}
              <div className={"card-order-container"}>
                {eventsMissed >= 7 && renderPlayerCard(eventsMissed)}
              </div>
              <div className={"player-coach"}>
                {coach}
              </div>
            </div>
            <div className="table-player-current-rating">
              {amountFormatter(rating)}
            </div>
            <div className="table-delta-last delta-decimals">
              {isPlayed > 0 && renderEventDelta(line)}

              {/* <div className="table-delta-last delta-icon"> */}
              {renderDeltaPlaces(prevPlace, place)}
              {/* </div> */}
            </div>

          </div>
          <div className="table-right-container">

            {renderLast4EventRatingContent(last4Matches)}
            <div className="table-actions">
              <EyeIcon onClick={(e) => showHistory(e, line)} className="show-history" />
            </div>
          </div>
        </div>
      )
    })

    return content
  }

  const visiblePlayerRating = showOnlyActivPlayers ? searhOnlyActivePlaers(rating, searchPlayer) : searchText(rating, searchPlayer)
  const playersTotalCount = showOnlyActivPlayers ? rating.filter(r => r.isPlayed > 0).length : rating.length

  const renderRatingTable = (showTopContainer = false) => {

    return (
      <div className={`new-table ${!showTopContainer && "desktop-table"}`}>
        {showTopContainer && <div className="table-title">
          <img src={topcup} alt="top-cup-logo" />
          <img src={qrCode} alt="top-cup-qr" className="qr-code" />
          {selectedEvent && <div className="print-heading">
            <h1>Рейтинг</h1>
            <h2>{selectedEvent.date}</h2>
            <h5>https://tca-rating.com/</h5>
          </div>}
        </div>}


        <div className="table-line rating-header-table" >
          <div className="table-left-container">
            <div className="table-place">
              #
            </div>
            <div className="table-player-name">
              Имя, фамилия
            </div>
            <div className="table-player-current-rating">
              R
            </div>
            <div className="table-delta-last delta-decimals">
              <div className="table-delta-last  delta-zero">𐤃</div>

            </div>

          </div>
          <div className="table-right-container">

            {visiblePlayerRating[0] && renderLast4EventHeader(visiblePlayerRating[0].last4Matches)}
            <div className="table-actions">
              #
            </div>
          </div>
        </div>


        {visiblePlayerRating && renderNewTable(visiblePlayerRating)}
      </div >
    )
  }


  const renderPlayersItems = () => {
    const playersArr = []

    ratingForCarousel.forEach((onePlayer, index) => {
      !onePlayer?.image.includes("no-image") && playersArr.push({
        onePlayer: <OnePlayer
          onePlayer={onePlayer}
          className={"one-player-avatar"}
          // isCurrentUser={Number(playerInfo.visibleInRating) > 0 && onePlayer.place === playerInfo.place}
          key={index}
        />,
      })
    })

    return playersArr
  }

  const renderTop5BestEventResult = () => {
    const content = eventStatistic.map((line, index) => {
      const { id, name, verificated, image, delta, isPlayed, wins, losses } = line
      return (
        <div className="table-left-container top-5" key={index}>

          <div className="table-player-name">
            <Link to={`/player/${id}`}>
              {renderPlayerName({ verificated, image, name })}
            </Link>

          </div>
          <div className="top-5-statistic">
            <div className="table-player-name">
              {renderEventDelta({ delta, isPlayed })}
            </div>
            <div className="table-player-name">
              Побед: <b>{wins}</b>
            </div>
            <div className="table-player-name">
              Поражений: <b>{losses}</b>
            </div>
          </div>

        </div>
      )
    })

    return content;
  }

  const bestEventResults = () => {
    return (
      <Grid container xs={14} className="best-player-events">
        <div className="best-line"></div>

        {rating.length > 0 &&
          <div className="best-title">
            <span>Статистика за {`${selectedEvent.name} (${selectedEvent.date})`}</span>
          </div>
        }
        <div>
          <div className="table-line" >
            <div className="table-place top-5">
              <h6>Лучший результат / TOP 5 </h6>
            </div>
            <div className="top-5-container">
              {rating.length > 0 ? renderTop5BestEventResult() : <h3>Нет данных </h3>}
            </div>

          </div>
        </div>
      </Grid>
    )
  }

  const renderAfishaItems = () => {
    const content = (afishaInfo || []).filter(i => Number(i.visible) === 1).map((item, index) => {
      const { date, title, description, cancelled, reason, afishaTitle } = item
      const formattedDate = moment(date).format("DD.MM.YYYY");

      const currentTimeHours = Number(moment().format("H"));
      const currentDayName = moment().format("dddd");

      const isSaturday = currentDayName === "Saturday" && index === 0;
      const isSunday = currentDayName === "Sunday" && index === 1

      const showIcon = currentTimeHours >= 10 && currentTimeHours <= 23
      return (
        <div
          className={classNames({
            'afisha-item': true,
            'is-registration': currentTimeHours >= 10 && currentTimeHours <= 12,
            'is-progress': currentTimeHours >= 12 && currentTimeHours <= 19,
            'is-end': currentTimeHours > 19 && currentTimeHours <= 23,
            'is-saturday': isSaturday,
            'is-sunday': isSunday,
          })}
          key={index}
        >

          {isSaturday &&
            <div className="afisha-tv currentday">

              <div className="time-track">{
                currentTimeHours >= 10 && currentTimeHours <= 12 ? "Регистрация" :
                  currentTimeHours >= 12 && currentTimeHours <= 19 ?
                    "Online" : currentTimeHours > 19 && currentTimeHours <= 23 ?
                      "Завершился" : ""
              }</div>
              <div className="see-translation">
                {showIcon && <YouTubeIcon className="you-tube-icon" onClick={() => enqueueSnackbar(("Трансляция скоро появится"), { variant: "warning" })} />}
              </div>
            </div>}
          {isSunday &&
            <div className="afisha-tv currentday">

              <div className="time-track">{
                currentTimeHours >= 12 && currentTimeHours <= 19 ?
                  "Online" : currentTimeHours > 19 && currentTimeHours <= 23 ?
                    "Завершился" : ""
              }</div>
              <div className="see-translation">
                {showIcon && <YouTubeIcon className="you-tube-icon" onClick={() => enqueueSnackbar(("Трансляция скоро появится"), { variant: "warning" })} />}
              </div>
            </div>}

          <div className={"afisha-title"}>
            {title} | {formattedDate}
          </div>
          <div className="best-line"></div>
          <div className={"afisha-event-title"}>
            {afishaTitle}
          </div>

          <div className="afisha-description" dangerouslySetInnerHTML={{ __html: description.replace(/\r\n/g, "<br />") }} />
          {Number(cancelled) === 1 && <div className="cancelled-container">
            <div className="cancelled-label">Турнир перенесен</div>
            <b>По причине:</b> {reason}
          </div>}

        </div>
      )
    })

    return content
  }

  return (
    <Box sx={{ flexGrow: 1, margin: "0 auto" }}>
      {renderHistoryModal()}
      {redirectId > 0 && <Redirect to={`/player/${redirectId}`} />}
      <Grid container spacing={1}>


        <Grid item xs={12} className="home-carousel">
          <Item className="home-carousel" sx={{ backgroundColor: "none" }}>
            {ratingForCarousel.length > 0 && <div>
              <CustomCarousel swipeable={true}
                draggable={true}
                showDots={false}
                arrows={true}
                infinite={true}
                autoPlay={true}
                desktopItems={6}
                autoPlaySpeedInSec={3}
                carouselItems={renderPlayersItems()}
              />
            </div>}
          </Item>
        </Grid>


        {afishaInfo.length > 0 && <div className="afisha-container-home-page">
          {isFetchingAfisha ? <CircularProgress className="circular-progress" size={18} /> : renderAfishaItems()}
        </div>}
        <div className="adaptive-rating-table">


          <div className="best-stat-search-players-container">
            {!fetchEventGames && !error && bestEventResults()}
            {!fetchEventGames &&
              <Grid container xs={10}
                className={classNames({
                  'event-search-player': true,
                  'no-margin': error
                  //`${cName}`: true
                })}
              >
                <Grid item xs={5} >
                  <Item>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={events}
                      autoHighlight
                      value={selectedEvent}
                      onChange={(event, newValue) => {
                        setSelectedEvent(newValue);
                      }}
                      getOptionLabel={(option) => `${option.name} (${option.date})`}
                      renderInput={(params) => <TextField {...params} label="Выберите событие" />}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name} ({option.date})
                        </Box>
                      )}
                    />
                  </Item>

                </Grid>
                <Grid item xs={7}>
                  <Item style={{ padding: "12px 0px" }}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="standard-adornment-password">Поиск игрока</InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={'text'}
                        value={searchPlayer}
                        onChange={(e) => setSearchPlayer(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setSearchPlayer("")}
                            >
                              {searchPlayer && <CloseRemoveIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Item>
                </Grid>
              </Grid>}

          </div>



        </div>
        <Grid item xs={12} style={{ textAlign: "center", minHeight: 200 }}>

          {fetchEventGames ? <Spinner /> :
            error ?
              <div className="no-rating">
                <img src={noCOntent} className="no-content-img" alt="no-content-img" />
                {error}
              </div> :

              <>
                <Grid item xs={12} className="show-activity">
                  <div>
                    <div
                      onClick={() => setShowOnlyActivPlayers(!showOnlyActivPlayers)}
                      className="show-only-participants"
                    >
                      {!showOnlyActivPlayers ? "Показать только участников" : "Показать всю таблицу"}
                    </div>
                    <div className="total-players">
                      Игроков: <b>{playersTotalCount}</b>
                    </div>
                  </div>

                  <Grid item xs={1} className="print-container">
                    {!fetchEventGames && <img src={print} onClick={handlePrint} className="print" alt="print" />}
                  </Grid>
                </Grid>
                {renderRatingTable()}
              </>

          }
        </Grid>

        {<div item xs={12} ref={componentRef} className="print-table">
          {renderRatingTable(true)}
        </div>}

      </Grid>

    </Box>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(RatingTable)
);